import { Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Mode from "../components/styles/Mode";

const Onboarding = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const navigate = useNavigate()
    return (
        <Mode>
            <div className="onboarding-page">
                <Spacebox padding="50px" />
                <Flexbox justifyContent="center" alignItems="center">
                    <div className="mid" style={{padding: '10px 20px'}}>
                        <Typography variant="h5">
                            <b>
                                Select dashboard type
                            </b>
                        </Typography>
                        <Spacebox padding="20px" />
                        <div className="shiny-holder pointer" style={{ opacity: .3 }}>
                            <Flexbox alignItems="center" justifyContent="space-between">
                                <div>
                                    <Typography variant="h6">
                                        <b>Pro</b>
                                    </Typography>
                                    <small>
                                        Full functionality dashboard allowing users to trade assets and manage their portfolio. <b>Available for Residents of any European Country.</b>
                                    </small>
                                </div>
                                <Spacebox padding="10px" />
                                <ChevronRight style={{ fontSize: '18px' }} />
                            </Flexbox>
                        </div>
                        <Spacebox padding="20px" />
                        <div className="shiny-holder pointer" onClick={() => navigate('/dashboard')}>
                            <Flexbox alignItems="center" justifyContent="space-between">
                                <div>
                                    <Typography variant="h6">
                                        <b>Lite</b>
                                    </Typography>
                                    <small>
                                        Simple dashboard enabling Mercuryopal's team of professional traders to manage and trade user portfolios efficiently. Accessible to all users.
                                    </small>
                                </div>
                                <Spacebox padding="10px" />
                                <ChevronRight style={{ fontSize: '18px' }} />
                            </Flexbox>
                        </div>
                    </div>
                </Flexbox>
            </div>
        </Mode>
    );
}

export default Onboarding;