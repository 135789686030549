import { Divider, Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Header from "../components/Header";
import Mode from "../components/styles/Mode";
import Spacebox from "../components/styles/Spacebox";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const NotFound = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const mode = useSelector(state => state.darkmode.value)
    return (
        <Mode>
            <div className="not-found">
                <Spacebox padding={isMobile ? "20px" : "0px"}>
                    <Header />
                </Spacebox>
                <Spacebox padding="50px" />
                <Flexbox justifyContent="center" alignItems="center">
                    <Typography style={isMobile ? {fontSize: 20} : { fontWeight: 700 }}>
                        OPPS!
                    </Typography>
                    <Spacebox padding="5px" />
                    <Divider orientation="vertical" flexItem style={mode.dark ? { filter: "invert(1)" } : {}} />
                    <Spacebox padding="5px" />
                    <Typography  style={isMobile ? {fontSize: 16} : {}}>
                        It seems this page doesn't exist
                    </Typography>
                </Flexbox>
            </div>
        </Mode>
    );
}

export default NotFound;