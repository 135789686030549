import { useNavigate } from "react-router-dom";
import Flexbox from "./Flexbox";
import requests from "../handlers/requests";
import { useState } from "react";
import Toast from "./Toast";
import cookies from "../utilities/Cookies";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const Topbar = () => {
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)
    const mode = useSelector(state => state.darkmode.value)

    const checkUrl = () => {
        let path = window.location.pathname
        if (path === "/") {
            return false
        } else if (path.includes('/enter-password')) {
            return false
        } else if (path.includes("/create-account")) {
            return false
        } else if (path.includes("/login")) {
            return false
        } else {
            return true
        }
    }

    const menues = [
        {
            title: "Home",
            icon: "/svgs/home.svg",
            path: "/dashboard",
            action: () => navigate('/dashboard')
        },
        {
            title: "Deposit",
            icon: "/svgs/sliders.svg",
            path: "/dashboard/deposit",
            action: () => navigate('/dashboard/deposit')
        },
        {
            title: "Analytics",
            icon: "/svgs/clock.svg",
            path: "/dashboard/analytics",
            action: () => navigate('/dashboard/analytics')
        },
        {
            title: "History",
            icon: "/svgs/clock.svg",
            path: "/dashboard/history",
            action: () => navigate('/dashboard/history')
        },
        {
            title: "Settings",
            icon: "/svgs/settings.svg",
            path: "/dashboard/settings",
            action: () => navigate('/dashboard/settings')
        },
        {
            title: "logout",
            icon: "/svgs/log-out.svg",
            path: "/",
            action: () => {
                requests.makeGet("/api/logout", setOpen, setSeverity, setToastMsg, setLoading,
                    (res) => {
                        cookies.deleteCookies("user")
                        navigate('/')
                    },
                    null
                )
            }
        },
    ]

    const mobile_menues = [
        {
            title: "Home",
            icon: "/svgs/home.svg",
            path: "/dashboard",
            action: () => navigate('/dashboard')
        },
        {
            title: "Deposit",
            icon: "/svgs/invest.svg",
            path: "/dashboard/deposit",
            action: () => navigate('/dashboard/deposit')
        },
        {
            title: "Analytics",
            icon: "/svgs/candle-sticks.svg",
            path: "/dashboard/analytics",
            action: () => navigate('/dashboard/analytics')
        },
        {
            title: "History",
            icon: "/svgs/clock.svg",
            path: "/dashboard/history",
            action: () => navigate('/dashboard/history')
        },
        {
            title: "Settings",
            icon: "/svgs/settings.svg",
            path: "/dashboard/settings",
            action: () => navigate('/dashboard/settings')
        },
    ]
    return (
        <div className="top-bar">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            {!loading && (
                <div>
                    {checkUrl() && <>
                        <Flexbox alignItems="center" className="hide-on-med-and-down" justifyContent="space-between">
                            {menues.map((menu, index) => (
                                <div key={index} onClick={menu.action} style={{ cursor: "pointer", marginRight: "20px" }}>
                                    <Flexbox alignItems="center">
                                        <span style={isMobile ? { fontSize: '18px' } : {}} className={menu.path === window.location.pathname ? "selected-page" : ""}>{menu.title}</span>
                                    </Flexbox>
                                </div>
                            ))}
                        </Flexbox>
                    </>}
                    {checkUrl() && <>
                        <Flexbox style={{ borderRadius: '10px', background: mode.dark ? '#f0f0f020' : "#0001", padding: '10px' }} alignItems="center" className="hide-on-large-only" justifyContent="space-between">
                            {mobile_menues.map((menu, index) => (
                                <div key={index} onClick={menu.action} style={{ cursor: "pointer", marginRight: "0px" }}>
                                    <Flexbox alignItems="center" justifyContent={"center"}>
                                        <img src={menu.icon} alt={menu.title} style={isMobile ? { width: 20, opacity: menu.path === window.location.pathname ? 1 : .5, filter: mode.dark ? 'invert(1)' : 'invert(0)' } : {}} />
                                    </Flexbox>
                                </div>
                            ))}
                        </Flexbox>
                    </>}
                </div>
            )}
        </div>
    );
}

export default Topbar;