import { createSlice } from '@reduxjs/toolkit'

export const darkMode = createSlice({
    name: 'darkmode',
    initialState: { value: { dark: false } },
    reducers: {
        updatedarkmode: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updatedarkmode } = darkMode.actions

export default darkMode.reducer