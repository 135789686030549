import { useEffect, useState } from "react";
import Toast from "../components/Toast";
import Spacebox from "../components/styles/Spacebox";
import { Divider, IconButton, Skeleton, Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { ChevronLeft, ChevronRight, WarningRounded } from "@mui/icons-material";
import Flexbox from "../components/Flexbox";
import requests from "../handlers/requests";
import { useNavigate } from "react-router-dom";

const Analytics = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState(null)
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrrentPage] = useState(0)

    const mode = useSelector(state => state.darkmode.value)
    const user = useSelector(state => state.user.value)

    const navigate = useNavigate()

    const determineOutlines = (outlines, transaction) => {
        let res = []
        const today = Date.now()
        // check if today is greater than week 1
        if (outlines[0].week_one <= today) {
            // check week 2
            if (outlines[1].week_two <= today) {
                // check week 3
                if (outlines[2].week_three <= today) {
                    // check week 4
                    if (outlines[3].week_four <= today) {
                        // update user amount and close transaction
                        requests.makeGet(`/api/complete/transaction?id=${user.id}&tid=${transaction.id}`, setOpen, setSeverity, setToastMsg, setLoading,
                            (res) => {
                                setTimeout(() => {
                                    navigate('/dashboard')
                                }, 4000);
                            },
                            "Funding wallet"
                        )
                        // send back res from wk 1 - 4 before redirect
                        res[0] = outlines[0]
                        res[1] = outlines[1]
                        res[2] = outlines[2]
                        res[3] = outlines[3]
                        return modify(res)
                    } else {
                        // send back res from wk 1 - 3
                        res[0] = outlines[0]
                        res[1] = outlines[1]
                        res[2] = outlines[2]
                        return modify(res)
                    }
                } else {
                    // send back res from wk 1 - 2
                    res[0] = outlines[0]
                    res[1] = outlines[1]
                    return modify(res)
                }
            } else {
                // send back res for wk 1 only
                res[0] = outlines[0]
                return modify(res)
            }
        } else {
            return res
        }
    }

    const renderOutlines = (outlines, transaction) => {
        const res = determineOutlines(outlines, transaction)
        return (
            <tbody>
                {res.map((outline, index) => (
                    <tr key={index}>
                        <td> {(new Date(outline.week)).toString().substring(4, 16)} </td>
                        <td>{outline.percentage * transaction.amount} <sub style={{fontSize: '13px'}}>BTC</sub></td>
                        <td>{(outline.percentage) * 100}%</td>
                    </tr>
                ))}
                {res.length < 1 && (
                    <tr>
                        <td colSpan={3}>
                            <Spacebox padding="10px" />
                            <Typography textAlign="center">
                                No recorded activies
                            </Typography>
                            <Spacebox padding="10px" />
                        </td>
                    </tr>
                )}
            </tbody>
        )
    }

    const handleClickLeft = (index) => {
        if (index === 0) {
            // do nothing
        } else {
            let i = index
            i = i - 1
            setCurrrentPage(i)
        }
    }

    const handleClickRight = (index) => {
        if (index === (pages - 1)) {
            // do nothing
        } else {
            let i = index
            i = i + 1
            setCurrrentPage(i)
        }
    }

    useEffect(() => {
        requests.makeGet('/api/transactions', setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                let alltrans = res.data
                setTransactions(alltrans.filter(t => t.active === true))
                setPages((alltrans.filter(t => t.active === true)).length)
            },
            null
        )
        // eslint-ignore-next-line
    }, [])

    function modify(arrOfObj) {
        let res = arrOfObj
        // change all week_one, week_two, week_three etc to week
        for (let x = 0; x < arrOfObj.length; x++) {
            res[x]["week"] = res[x][`week_${toWord(x)}`]
            delete res[x][`week_${toWord(x)}`]
        }
        return res
    }

    function toWord(index) {
        if (index === 0) {
            return 'one'
        } else if (index === 1) {
            return 'two'
        } else if (index === 2) {
            return 'three'
        } else {
            return 'four'
        }
    }

    return (
        <div className="transactions">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Spacebox padding="20px" />
            {loading && (
                <div>
                    <CustomGrid grid={isMobile ? 1 : 2} gap="20px">
                        <Skeleton width="100%" height="200px" style={{ borderRadius: '20px' }} />
                        <Skeleton width="100%" height="200px" style={{ borderRadius: '20px' }} />
                    </CustomGrid>
                    <Spacebox padding="10px" />
                    <Skeleton width="100%" height="400px" style={{ borderRadius: '20px' }} />
                </div>
            )}
            {transactions && (
                <div>
                    {transactions.length < 1 && (
                        <Flexbox justifyContent="center" alignItems="center">
                            <WarningRounded />
                            <Spacebox padding="5px" />
                            <Divider orientation="vertical" flexItem style={mode.dark ? { filter: "invert(1)" } : {}} />
                            <Spacebox padding="5px" />
                            <Typography style={isMobile ? { fontSize: 16 } : {}}>
                                You currently have no active investment
                            </Typography>
                        </Flexbox>
                    )}
                    {transactions.length > 0 && (
                        <div>
                            {transactions.map((transaction, index) => (
                                <div key={index}>
                                    {index === currentPage && <div>
                                        <Flexbox alignItems="center" justifyContent="space-between">
                                            <Typography style={{ fontSize: '35px', fontWeight: 800 }}>
                                                Active Investment
                                            </Typography>
                                            <Flexbox alignItems="center" className="hide-on-med-and-down">
                                                <IconButton onClick={() => handleClickLeft(index)}>
                                                    <ChevronLeft style={{ opacity: index === 0 ? .3 : 1 }} />
                                                </IconButton>
                                                <Spacebox padding="10px" />
                                                <IconButton onClick={() => handleClickRight(index)}>
                                                    <ChevronRight style={{ opacity: index === (pages - 1) ? .3 : 1 }} />
                                                </IconButton>
                                            </Flexbox>
                                        </Flexbox>
                                        <div className="hide-on-large-only">
                                            <Spacebox padding="10px" />
                                            <Flexbox alignItems="center" justifyContent="space-between">
                                                <IconButton onClick={() => handleClickLeft(index)}>
                                                    <ChevronLeft style={{ opacity: index === 0 ? .3 : 1 }} />
                                                </IconButton>
                                                <Spacebox padding="10px" />
                                                <IconButton onClick={() => handleClickRight(index)}>
                                                    <ChevronRight style={{ opacity: index === (pages - 1) ? .3 : 1 }} />
                                                </IconButton>
                                            </Flexbox>
                                        </div>
                                        <Spacebox padding="10px" />
                                        <CustomGrid grid={isMobile ? 1 : 2} gap="20px">
                                            <div className="gradient-animate" style={{ padding: isMobile ? '20px' : '40px', borderRadius: '20px' }}>
                                                <span>Investment Amount</span>
                                                <Typography style={{ fontSize: isMobile ? "40px" : '60px', fontWeight: 800 }}>
                                                    {transaction.amount} <sub style={{ fontSize: '10px' }}>BTC</sub>
                                                </Typography>
                                                <span>amount invested on {(new Date(transaction.timestamp)).toString().substring(4, 16)}</span>
                                            </div>
                                            <div className="gradient-animate" style={{ padding: isMobile ? '20px' : '40px', borderRadius: '20px' }}>
                                                <span>Return of Investment</span>
                                                <Typography style={{ fontSize: isMobile ? "40px" : '60px', fontWeight: 800 }}>
                                                    {(transaction.percentage) * 100}%
                                                </Typography>
                                                <span>Estimated return of investment based on weekly market trend</span>
                                            </div>
                                        </CustomGrid>
                                        <Spacebox padding="20px" />
                                        <Typography style={{ fontSize: '35px', fontWeight: 800 }}>
                                            Investment Trends
                                        </Typography>
                                        <Spacebox padding="10px" />
                                        <div className="table-holder hide-scrollbar">
                                            <table className={mode.dark ? "dark-table" : "light-table"}>
                                                <thead>
                                                    <tr>
                                                        <td>DATE</td>
                                                        <td>AMOUNT</td>
                                                        <td>PERCENTAGE</td>
                                                    </tr>
                                                </thead>
                                                {renderOutlines(transaction.outlines, transaction)}
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Analytics;