import { useSelector } from "react-redux";
import TradingView from "../components/TradingView";
import { IconButton, Skeleton, Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import useFetch from "../hooks/useFetch";
import { Link, useNavigate } from "react-router-dom";
import string from "../utilities/Strings";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import CustomButton from "../components/styles/Custombutton";
import { DotSpinner } from "@uiball/loaders";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Dashboard = ({ title }) => {

    document.querySelector("title").innerHTML = title

    const navigate = useNavigate()

    const mode = useSelector(state => state.darkmode.value)
    const user = useSelector(state => state.user.value)
    const { data: transactions, error, isLoading } = useFetch('/api/transactions')
    const [verif, setVerif] = useState(false)
    const [step, setStep] = useState(1)

    const menus = [
        {
            name: "Account Balance",
            amount: user.balance
        },
        {
            name: "Total Deposit",
            amount: user.total_deposit
        },
        {
            name: "Total Earned",
            amount: user.total_earned
        },
        {
            name: "Active Deposit",
            amount: user.active_deposit
        },
        {
            name: "Total Withdraw",
            amount: user.total_withdraw
        },
        {
            name: "Pending Withdraw",
            amount: user.pending_withdraw
        },

    ]

    const statusColor = {
        "complete": "aquamarine",
        "failed": "red",
        "pending": "lightblue"
    }

    const statusElement = (status) => (
        <Flexbox alignItems={"center"} justifyContent={"space-between"} style={{ padding: '5px', border: '1px solid #dbdbdb4a', borderRadius: '1000px', width: '100px' }}>
            <small>{string.capitalise(status)}</small>
            <Spacebox padding="5px 10px" />
            <div style={{
                background: `${statusColor[status]}`,
                width: '10px',
                height: '10px',
                borderRadius: '1000px',
            }}></div>
        </Flexbox>
    )

    return (
        <div className="dashboard">
            <Typography variant="h6" style={{ fontWeight: "800" }}>
                Hello {user.firstname} 👋,
            </Typography>
            <Typography variant="body2" className="lighten-text">
                What are we doing today?
            </Typography>
            <Spacebox padding="20px" />
            <Flexbox alignItems={"center"} justifyContent={"center"}>
                <TradingView mode={mode.dark ? "dark" : "light"} />
            </Flexbox>
            <Spacebox padding="20px" />
            <Flexbox justifyContent="space-between" alignItems="center">
                <Typography style={{ fontWeight: 800, fontSize: isMobile ? "25px" : '32px' }}>
                    Balance
                </Typography>
                <Flexbox alignItems="center" >
                    <span style={{ cursor: 'pointer' }} onClick={() => {
                        if(user.balance === 0) {
                            window.alert("Balance too low")
                        } else {
                            setVerif(true)
                        } 
                    }}>Withdraw</span>
                    <Spacebox padding="5px" />
                    <IconButton onClick={() => {
                        if(user.balance === 0) {
                            window.alert("Balance too low")
                        } else {
                            setVerif(true)
                        } 
                    }}>
                        <ArrowForwardIcon  style={{fontSize: '16px'}} />
                    </IconButton>
                </Flexbox>
            </Flexbox>
            <Spacebox padding="10px" />
            <CustomGrid grid={isMobile ? 2 : 3} gap="20px">
                {menus.map((menu, index) => (
                    <div className="shiny-holder" key={index}>
                        <Typography style={isMobile ? { fontSize: '16px' } : {}}>
                            {menu.name}
                        </Typography>
                        <Spacebox padding="5px" />
                        <Typography variant={isMobile ? "h5" : "h2"}>
                            {(menu.amount).toLocaleString()} <sub><small style={{ fontSize: isMobile ? '8px' : '13px' }}>BTC</small></sub>
                        </Typography>
                    </div>
                ))}
            </CustomGrid>
            <Spacebox padding="20px" />
            <Flexbox justifyContent="space-between" alignItems="center">
                <Typography style={{ fontWeight: 800, fontSize: isMobile ? "20px" : '32px' }}>
                    Recent Transaction
                </Typography>
                <Link style={{ color: '#089981' }} to="/dashboard/history">See more</Link>
            </Flexbox>
            <Spacebox padding="10px" />
            <div className="table-holder hide-scrollbar">
                <table className={mode.dark ? "dark-table" : "light-table"}>
                    <thead>
                        <tr>
                            <td>DATE</td>
                            <td>AMOUNT</td>
                            <td>TYPE</td>
                            <td>DESCRIPTION</td>
                            <td>STATUS</td>
                        </tr>
                    </thead>
                    {error && (
                        <tbody>
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'center' }}>
                                    An error occured
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {isLoading && (
                        <tbody>
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'center' }}>
                                    <Skeleton variant="rounded" style={{ width: "100%" }} height={60} />
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {(transactions && transactions.length > 0) && (
                        <tbody>
                            {(transactions.slice(0, 5)).map((transac, index) => (
                                <tr key={index}>
                                    <td>{(new Date(transac.timestamp)).toString().substring(0, 16)}</td>
                                    <td>{(transac.amount).toLocaleString()} <small style={{ fontSize: '8px' }}>BTC</small></td>
                                    <td>{transac.type}</td>
                                    <td>{transac.desc}</td>
                                    <td>{statusElement(transac.status)}</td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                    {(transactions && transactions.length === 0) && (
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <span style={{ display: 'block', textAlign: 'center', margin: '20px 0px' }} className="lighten-text">No records found</span>

                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
            {verif && (
                <div className="glass" style={{ borderRadius: '0px', border: '0px solid transparent', position: 'fixed', zIndex: 100, top: 0, left: 0, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="mid bg" style={{ borderRadius: '20px' }}>
                    {step === 1 && <Spacebox padding="40px">
                        <Typography variant="h4" className="bold">
                            Suspicious activies detected
                        </Typography>
                        <Spacebox padding="10px" />
                        <Typography>
                             We have detected some abnormal activities on your account and require your identity verification to continue.<br /> <br />
                            This will require you to upload a copy of a government issued ID such as:
                        </Typography>
                        <ul>
                            <li>International Passport  <small style={{ display: 'inline-block', fontSize: '8px', textTransform: 'uppercase', letterSpacing: '3px' }}>(recommended)</small></li>
                            <li>National ID</li>
                            <li>Driver's license</li>
                        </ul>
                        <Spacebox padding="10px" />
                        <CustomButton style={{ background: 'var(--primary)', color: "white", padding: '15px 0px', borderRadius: '10px' }} className="fullwidth" handleClick={() => {
                            setStep(2)
                            setTimeout(() => {
                                if (isMobile)
                                    navigate('/metamap')
                                else {
                                    window.alert("Kindly complete process with your mobile phone")
                                    setVerif(false)
                                    setStep(1)
                                }
                            }, 3000);
                        }}>
                            Begin Identity Verification
                        </CustomButton>
                    </Spacebox>}
                    {step === 2 && <Spacebox padding="40px">
                        <Flexbox alignItems="center" justifyContent="center">
                            <div>
                                <Flexbox alignItems="center" justifyContent="center">
                                    <DotSpinner size={30} color="white" />
                                </Flexbox>
                                <Spacebox padding="5px" />
                                <small>Establishing connection to metaMap</small>
                            </div>
                        </Flexbox>
                    </Spacebox>}
                </div>
            </div>
            )}
        </div>
    );
}

export default Dashboard;