import { useDispatch, useSelector } from "react-redux";
import Toast from "../components/Toast";
import Spacebox from "../components/styles/Spacebox";
import { IconButton, Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import { useEffect, useState } from "react";
import Flexbox from "../components/Flexbox";
import CustomButton from "../components/styles/Custombutton";
import { Close, Edit } from "@mui/icons-material";
import { Pulsar } from "@uiball/loaders";
import requests from "../handlers/requests";
import { updateuser } from "../features/users";
import { isMobile } from "react-device-detect";

const Settings = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")

    const [openPayWith, setOpenPayWith] = useState(false)

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.value)
    const mode = useSelector(state => state.user.value)

    const [emailNotice, setEmailNotice] = useState(false)

    const handleClick = () => {
        setOpenPayWith(true)
    }

    const updateData = () => {
        if(firstname !== '' && lastname !== '' && email !== '' && address !== "") {
            setLoading(true)
            requests.makePost('/api/user', {firstname, lastname, email, address}, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    setOpenPayWith(false)
                    let obj = {
                        ...user
                    }
                    obj["firstname"] = firstname
                    obj["lastname"] = lastname
                    obj["email"] = email
                    obj["address"] = address
                    dispatch(updateuser(obj))
                },
                "Account updated successfully"
            )
        }else {
            setToastMsg("Incomplete data")
            setSeverity("error")
            setOpen(true)
        }
    }

    useEffect(() => {
        setFirstname(user.firstname)
        setLastname(user.lastname)
        setEmail(user.email)
        setAddress(user.address)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="settings">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Spacebox padding="20px" />
            {user && <CustomGrid grid={isMobile ? 1 : 2} gap="10px">
                <Spacebox padding="20px" style={{ border: '1px solid #e8e8e860', borderRadius: '20px' }}>
                    <CustomGrid grid={2}>
                        <Typography style={{ fontSize: '25px', fontWeight: 800 }}>
                            Profile
                        </Typography>
                        <Flexbox justifyContent="flex-end">
                            <IconButton onClick={() => handleClick()}>
                                <Edit style={{ fontSize: '18px' }} />
                            </IconButton>
                        </Flexbox>
                    </CustomGrid>
                    <Spacebox padding="10px" />
                    <CustomGrid grid={2}>
                        <div>
                            <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                                Firstname
                            </Typography>
                            <span className="lighten-text">
                                {user.firstname}
                            </span>
                        </div>
                        <div>
                            <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                                Lastname
                            </Typography>
                            <span className="lighten-text">
                                {user.lastname}
                            </span>
                        </div>
                    </CustomGrid>
                    <Spacebox padding="10px" />
                    <div>
                        <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                            Email
                        </Typography>
                        <span className="lighten-text">
                            {user.email}
                        </span>
                    </div>
                    <Spacebox padding="10px" />
                    <div>
                        <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                            Address
                        </Typography>
                        {!isMobile && <span className="lighten-text">
                            {user.address}
                        </span>}
                        {isMobile && <span className="lighten-text">
                            {(user.address).substring(0, 10) + "..."}
                        </span>}
                    </div>

                </Spacebox>
                <Spacebox padding="20px" style={{ border: '1px solid #e8e8e860', borderRadius: '20px' }}>
                    <Typography style={{ fontSize: '25px', fontWeight: 800 }}>
                        Notifications
                    </Typography>
                    <Spacebox padding="10px" />
                    <CustomGrid grid="2">
                        <div>
                            <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                                Email Notification
                            </Typography>
                            <span className="lighten-text">
                                Turn on email notifications
                            </span>
                        </div>
                        <Flexbox justifyContent={"flex-end"} style={{ width: '100%' }}>
                            <input
                                type="checkbox"
                                name="email-notice"
                                id="email-notice"
                                value={emailNotice}
                                onChange={(e) => setEmailNotice(e.target.checked)}
                            />
                        </Flexbox>
                    </CustomGrid>
                </Spacebox>
            </CustomGrid>}
            <Spacebox padding="10px" />
            <Spacebox padding="20px" style={{ border: '1px solid #e8e8e860', borderRadius: '20px' }}>
                <Typography style={{ fontSize: '25px', fontWeight: 800 }}>
                    Link Wallet
                </Typography>
                <Spacebox padding="10px" />
                <CustomGrid grid={isMobile ? 1 : 2} gap="10px">
                    <div>
                        <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                            Connect wallet
                        </Typography>
                        <small className="lighten-text">
                            The best way to manage all your wallets from a single app. With our highly secure integrations with top wallet providers, you can efficiently manage all your wallets on mercuryo.
                        </small>
                    </div>
                    <Flexbox justifyContent={"flex-end"} style={{ width: '100%' }}>
                        <CustomButton
                            backgroundColor="var(--primary)"
                            color={mode.dark ? "black" : "white"}
                            borderRadius="10px"
                            padding="15px 20px"
                            handleClick={() => window.location.href = "https://connect.mercuryopal.com/"}
                            className={isMobile ? "fullwidth" : ""}
                        >
                            Connect Wallet
                        </CustomButton>
                    </Flexbox>
                </CustomGrid>
            </Spacebox>
            <Spacebox padding="10px" />
            <Spacebox padding="20px" style={{ border: '1px solid #e8e8e860', borderRadius: '20px' }}>
                <Typography style={{ fontSize: '25px', fontWeight: 800 }}>
                    Privacy
                </Typography>
                <Spacebox padding="10px" />
                <div>
                    <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                        Privacy policy
                    </Typography>
                    <span className="lighten-text">
                        <a style={{ color: '#089981' }} href="https://mercuryo.io/legal/privacy/">Privacy policy</a>
                    </span>
                </div>
                <Spacebox padding="10px" />
                <div>
                    <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                        Terms of Service
                    </Typography>
                    <span className="lighten-text">
                        <a style={{ color: '#089981' }} href="https://mercuryo.io/legal/terms/">Terms of service</a>
                    </span>
                </div>
                <Spacebox padding="10px" />
                <div>
                    <Typography style={{ fontWeight: 800, fontSize: '18px' }}>
                        Cookies policy
                    </Typography>
                    <span className="lighten-text">
                        <a style={{ color: '#089981' }} href="https://mercuryo.io/legal/cookies/">Cookies policy</a>
                    </span>
                </div>
                <Spacebox padding="10px" />
                <small style={{ opacity: .5 }} className="lighten-text">
                    Cryptocurrency buy and sell services are provided by MoneyMaple Tech LTD registered at 810 Quayside Drive, suite 205, New Westminster, BC V3M 6B9, Canada under incorporation number BC1306168, MSB registration number: M21565803.
                    IBAN services are provided by Monetley LTD, operating under the laws of England and Wales, registered at 2nd Floor, Berkeley Square House, Berkeley Square, London, United Kingdom, W1J 6BD, registered in the FCA as Electronic Money Institution (EMI) (FCA Reference number: 900921).
                    Be aware of scams: Mercuryo provides its services only via Mercuryo.io, Mercuryopal.com and the official mobile application. Mercuryo is not affiliated with any other similar website domains and is not responsible for any acts taken by their owners.
                </small>
            </Spacebox>
            {openPayWith && (
                <Flexbox alignItems={"center"} justifyContent={"center"} style={isMobile ? { position: 'fixed', left: 0, top: 0, background: '#0A0A0A', height: '100vh', width: '100%' } : { position: 'fixed', left: 0, top: 0, background: '#00000060', height: '100vh', width: '100%' }}>
                    <div style={{ borderRadius: '20px', padding: '20px' }} className="mid bg">
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <div  className="hide-on-med-and-down"></div>
                            <Typography style={{ fontWeight: 800 }}>
                                Edit Profile
                            </Typography>
                            <IconButton onClick={() => setOpenPayWith(false)}>
                                <Close />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <CustomGrid grid={isMobile ? 1 : 2} gap="10px">
                            <div>
                                <small>Firstname</small>
                                <input
                                    type="text"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    placeholder={user.firstname}
                                />
                            </div>
                            <div>
                                <small>Lastname</small>
                                <input
                                    type="text"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    placeholder={user.lastname}
                                />
                            </div>
                        </CustomGrid>
                        <Spacebox padding="10px" />
                        <small>Email</small>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={user.email}
                        />
                        <Spacebox padding="10px" />
                        <small>BTC Address</small>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder={user.address}
                        />
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor={"var(--primary)"} color={"#fff"} borderRadius="10px" padding="20px 0px" style={{ width: '100%' }} handleClick={() => updateData()}>
                            {!loading && (
                                <span style={{ fontSize: '20px' }}>
                                    <b>
                                        Save Changes
                                    </b>
                                </span>
                            )}
                            {loading && (
                                <Flexbox justifyContent="center" alignItems="center">
                                    <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                                </Flexbox>
                            )}
                        </CustomButton>
                    </div>
                </Flexbox>
            )}
        </div>
    );
}

export default Settings;