import { useDispatch, useSelector } from "react-redux";
import Flexbox from "./Flexbox";
import Logo from "./Logo";
import Spacebox from "./styles/Spacebox";
import { DarkModeOutlined, LightModeOutlined, LogoutOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { updatedarkmode } from "../features/mode";
import cookies from "../utilities/Cookies";
import Topbar from "./Topbar";
import { isMobile } from "react-device-detect";
import requests from "../handlers/requests";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Toast from "./Toast";

const Header = () => {
    const mode = useSelector(state => state.darkmode.value)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user_stringify = cookies.getCookies("user")

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [, setLoading] = useState(false)


    const handleDarkMode = (bool) => {
        const obj = { dark: bool }
        dispatch(updatedarkmode(obj))
        cookies.setCookies("mode", bool ? "dark" : "light", 20)
    }

    const logout = () => {
        requests.makeGet("/api/logout", setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                cookies.deleteCookies("user")
                navigate('/')
            },
            null
        )
    }

    return (
        <div className="header">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Flexbox alignItems="center" justifyContent="space-between">
                <Logo />
                <Flexbox alignItems="center" justifyContent="flex-end">
                    {user_stringify.length > 5 && (
                        <div className="hide-on-med-and-down">
                            <Topbar />
                        </div>
                    )}
                    <Spacebox padding="5px" />
                    {!mode.dark && (
                        <IconButton onClick={() => handleDarkMode(true)}>
                            <LightModeOutlined style={{ fontSize: '20px' }} />
                        </IconButton>
                    )}
                    {mode.dark && (
                        <IconButton onClick={() => handleDarkMode(false)}>
                            <DarkModeOutlined style={{ fontSize: '20px' }} />
                        </IconButton>
                    )}
                    {(isMobile && user_stringify.length > 5) && (
                        <IconButton onClick={() => logout()}>
                            <LogoutOutlined style={{ fontSize: '20px' }} />
                        </IconButton>
                    )}
                </Flexbox>
            </Flexbox>
            {user_stringify.length > 5 && (
                <div className="hide-on-large-only">
                    <Spacebox padding="10px" />
                    <Topbar />
                </div>
            )}
        </div>
    );
}

export default Header;