import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Spacebox from "./styles/Spacebox";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from "react";
import { updateuser } from "../features/users";
import Flexbox from "./Flexbox";
import { Typography } from "@mui/material";
import Toast from "./Toast";
import cookies from "../utilities/Cookies";
import { Pulsar } from '@uiball/loaders';
import Mode from "./styles/Mode";
import { isMobile } from "react-device-detect";

const Layout = () => {

    const user_stringify = cookies.getCookies('user')
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const navigate = useNavigate()
    const mode = useSelector(state => state.darkmode.value)


    useEffect(() => {
        if (user_stringify === "" || user_stringify.length < 10) {
            navigate("/")
        }

        fetch('/api/user', { credentials: "include" })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    dispatch(updateuser(res.data))
                    setLoading(false)
                    setSuccess(true)
                    setError(false)
                } else {
                    if (res.error === "Unauthorised request") {
                        cookies.deleteCookies("user")
                        navigate('/')
                    }

                    console.log(res.error)
                    setToastMsg(res.msg)
                    setOpen(true)
                    setSeverity('error')
                    setLoading(false)
                    setError(true)
                }
            }).catch(err => {
                console.log(err)
                setToastMsg('An error occured, Try again')
                setOpen(true)
                setSeverity('error')
                setLoading(false)
                setError(true)
            })
        //eslint-disable-next-line
    }, [])

    return (
        <Mode>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            {loading && <Flexbox justifyContent="center" alignItems="center" className="full">
                <div className="small" style={{ textAlign: "center" }}>
                    <Flexbox justifyContent="center"><Pulsar size={80} speed={0.9} color={mode.dark ? "white" : "black"} /></Flexbox>
                </div>
            </Flexbox>}
            {success && <div style={isMobile ? {padding: '20px'} : {}}>
                <Header />
                <Spacebox padding="20px" />
                <div className="layout">
                    <Outlet />
                </div>
            </div>}
            {error && <Flexbox justifyContent="center" alignItems="center" className="full">
                <div className="mid" style={{ textAlign: "center" }}>
                    <img src="/assets/broken-link.png" alt="" style={{ width: '200px', filter: mode.dark ? "invert(0)" : "invert(1)" }} />
                    <Typography variant="h6" className="bold"> There seems to be a problem </Typography>
                </div>
            </Flexbox>}
        </Mode>
    );
}

export default Layout;