import "../../css/light.css"

const Lightmode = ({children}) => {
    return (
        <div className="light-mode">
            {children}
        </div>
    );
}
 
export default Lightmode;