import { IconButton, Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import Toast from "../components/Toast";
import { useState } from "react";
import { Close, CopyAll } from "@mui/icons-material";
import CustomButton from "../components/styles/Custombutton";
import requests from "../handlers/requests";
import { useNavigate, useParams } from "react-router-dom";
import { Pulsar } from "@uiball/loaders";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const Fund = ({ title }) => {

    document.querySelector("title").innerHTML = title

    const navigate = useNavigate()
    const { plan } = useParams()
    const mode = useSelector(state => state.darkmode.value)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [amount, setAmount] = useState('')
    const [btc, setBtc] = useState('')
    const [openPayWith, setOpenPayWith] = useState(false)

    const address = "13c3BzsjhexYzSu6oa5wEzEN3RSFZMKj4i"

    const amountConverter = (amount) => {
        if (amount === "") {
            setAmount(0)
        } else {
            setAmount(amount)
            fetch(`https://blockchain.info/tobtc?currency=USD&value=${amount}`)
                .then(res => res.json())
                .then(res => {
                    setBtc(res)
                }).catch(err => console.log(err))
        }
    }

    // const getBTCValue = async (usd) => {
    //     let val = await fetch(`https://blockchain.info/tobtc?currency=USD&value=${usd}`)
    //     let res = await val.json()
    //     return res
    // }

    const handleClick = () => {
        if (amount < 1300) {
            setToastMsg("Amount too small")
            setSeverity("error")
            setOpen(true)
        } else {
            setOpenPayWith(true)
        }
    }

    const madeTransfer = () => {
        setLoading(true)
        requests.makePost('/api/transaction', { amount: btc, type: 'Deposit', desc: 'Account Fund', plan: plan }, setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setTimeout(() => {
                    navigate("/dashboard")
                }, 4000);
            },
            "Transaction pending"
        )
    }

    return (
        <div className="fund">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Spacebox padding="20px" />
            <CustomGrid grid={isMobile ? 1 : "2"} gap="40px">
                <div>
                    <Typography variant={isMobile ? 'h6' : "h4"} style={{ fontWeight: 800 }}>
                        Fund Wallet
                    </Typography>
                    <Typography style={isMobile ? { fontSize: '16px' } : {}}>
                        <b>Get deposit address for BTC </b> — Deposit funds via a blockchain
                    </Typography>
                    <Spacebox padding="10px" />
                    <Typography style={{ fontWeight: 800, fontSize: '16px' }}>
                        Coin
                    </Typography>
                    <Spacebox padding="20px" style={{ border: '1px solid #f0f0f0', borderRadius: '10px', margin: '10px auto' }}>
                        <span>BTC</span>
                    </Spacebox>
                    <Spacebox padding="5px" />
                    <Typography style={{ fontWeight: 800, fontSize: '16px' }}>
                        Network
                    </Typography>
                    <Spacebox padding="20px" style={{ border: '1px solid #f0f0f0', borderRadius: '10px', margin: '10px auto' }}>
                        <span>BTC <small className="lighten-text">Bitcoin</small></span>
                    </Spacebox>
                    <Spacebox padding="5px" />
                    <Typography style={{ fontWeight: 800, fontSize: '16px' }}>
                        Deposit Address
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        window.navigator.clipboard.writeText("13c3BzsjhexYzSu6oa5wEzEN3RSFZMKj4i")
                        setToastMsg("Copied")
                        setSeverity("success")
                        setOpen(true)
                    }}>
                        <Spacebox padding="20px" style={{ border: '1px solid #f0f0f0', borderRadius: '10px', margin: '10px auto' }}>
                            <Flexbox alignItems="center">
                                <span className="lighten-text">
                                    13c3Bz...
                                </span>
                                <Spacebox padding="5px" />
                                <CopyAll style={{ fontSize: '20px' }} />
                            </Flexbox>
                        </Spacebox>
                    </div>
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <small className="lighten-text">Minimum deposit</small>
                        <small className="lighten-text"><b> ($1,300) </b></small>
                    </Flexbox>
                    <Spacebox padding="5px" />
                    <small className="lighten-text" >Deposits should be made to your address above. In other not to lose your assets, ensure you copy your address correctly.</small>
                    <Spacebox padding="5px" />
                </div>
                <div>
                    <Spacebox padding="20px" style={{ border: '1px solid #e0e0e0', borderRadius: '20px', }}>
                        <Spacebox padding="20px" style={{ border: '1px solid #e8e8e8', borderRadius: '20px' }}>
                            <Flexbox alignItems="flex-end" justifyContent={"space-between"}>
                                <div>
                                    <small>Spend</small>
                                    <input
                                        type="number"
                                        placeholder="1,300 - 120,000"
                                        value={amount}
                                        onChange={(e) => amountConverter(e.target.value)}
                                        style={{ fontSize: '20px', fontWeight: 800 }}
                                    />
                                </div>
                                <Spacebox padding="2.5px" />
                                <Flexbox alignItems="center">
                                    <img src="/assets/usd.png" style={{ width: '20px' }} alt="usd" />
                                    <Spacebox padding="2px" />
                                    <b>USD</b>
                                </Flexbox>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding="20px" />
                        <Spacebox padding="20px" style={{ border: '1px solid #e8e8e8', borderRadius: '20px' }}>
                            <Flexbox alignItems="flex-end" justifyContent={"space-between"}>
                                <div>
                                    <small>Recieve</small>
                                    <input
                                        type="number"
                                        placeholder="0.00"
                                        value={btc}
                                        disabled
                                        onChange={(e) => setBtc(e.target.value)}
                                        style={{ fontSize: '20px', fontWeight: 800 }}
                                    />
                                </div>
                                <Spacebox padding="2.5px" />
                                <Flexbox alignItems="center">
                                    <img src="/assets/btc.png" style={{ width: '20px' }} alt="usd" />
                                    <Spacebox padding="2px" />
                                    <b>BTC</b>
                                </Flexbox>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor={"#089981"} color={"#fff"} borderRadius="10px" padding="20px 0px" style={{ width: '100%' }} handleClick={handleClick}>
                            <span style={{ fontSize: '20px' }}>
                                <b>
                                    Proceed to Fund Wallet
                                </b>
                            </span>
                        </CustomButton>
                    </Spacebox>
                </div>
            </CustomGrid>
            {openPayWith && (
                <Flexbox alignItems={"center"} justifyContent={"center"} style={isMobile ? { position: 'fixed', left: 0, top: 0, background: '#0A0A0A', height: '100vh', width: '100%' } : { position: 'fixed', left: 0, top: 0, background: '#00000060', height: '100vh', width: '100%' }}>
                    <div style={{ borderRadius: '20px', padding: '20px' }} className="mid bg">
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <div className="hide-on-med-and-down"></div>
                            <Typography style={{ fontWeight: 800 }}>
                                Pay With
                            </Typography>
                            <IconButton onClick={() => setOpenPayWith(false)}>
                                <Close />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="20px" />
                        <Typography>Payment</Typography>
                        <Spacebox padding="10px" />
                        <Spacebox padding="20px" style={{ border: '1px solid #e8e8e870', borderRadius: '10px' }}>
                            <Flexbox justifyContent="space-between" alignItems="center">
                                <Flexbox alignItems="center">
                                    <img src="/assets/btc.png" style={{ width: '20px' }} alt="" />
                                    <Spacebox padding="5px" />
                                    <span className="lighten-text"><b>Wallet fund</b></span>
                                </Flexbox>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding="5px" />
                        <small style={{ fontSize: '13px' }} className="lighten-text">Transfer {btc} BTC = ${amount} from your external wallet i.e Trustwallet, Atomic Wallet, Binance, etc to your Mercuryo Pal Wallet using the Address below</small>
                        <Spacebox padding="10px" />
                        <Typography style={{ fontWeight: 800, fontSize: '16px' }}>
                            Deposit Address
                        </Typography>
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            window.navigator.clipboard.writeText("13c3BzsjhexYzSu6oa5wEzEN3RSFZMKj4i")
                            setToastMsg("Copied")
                            setSeverity("success")
                            setOpen(true)
                        }}>
                            <Spacebox padding="20px" style={{ border: '1px solid #f0f0f0', borderRadius: '10px', margin: '10px auto' }}>
                                <Flexbox alignItems="center">
                                    {isMobile && <span className="lighten-text">
                                        {address.substring(0, 10) + "..."}
                                    </span>}
                                    {!isMobile && <span className="lighten-text">
                                        {address}
                                    </span>}
                                    <Spacebox padding="5px" />
                                    <CopyAll style={{ fontSize: '20px' }} />
                                </Flexbox>
                            </Spacebox>
                        </div>
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <small className="lighten-text">Minimum deposit</small>
                            <small className="lighten-text"><b> ($1,300) </b></small>
                        </Flexbox>
                        <Spacebox padding="5px" />
                        <small className="lighten-text" >Deposits should be made to your address above. In other not to lose your assets, ensure you copy your address correctly.</small>
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor={"var(--primary)"} color={"#fff"} borderRadius="10px" padding="20px 0px" style={{ width: '100%' }} handleClick={() => madeTransfer()}>
                            {!loading && (
                                <span style={{ fontSize: '20px' }}>
                                    <b>
                                        I have made the transfer
                                    </b>
                                </span>
                            )}
                            {loading && (
                                <Flexbox justifyContent="center" alignItems="center">
                                    <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                                </Flexbox>
                            )}
                        </CustomButton>
                    </div>
                </Flexbox>
            )}
        </div>
    );
}

export default Fund;