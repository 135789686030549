import "../../css/dark.css"


const Darkmode = ({children}) => {
    return (
        <div className="dark-mode">
            {children}
        </div>
    );
}
 
export default Darkmode;