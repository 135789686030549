import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import Password from './pages/Password';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Deposit from './pages/Deposit';
import Fund from './pages/Fund';
import History from './pages/History';
import Settings from './pages/Settings';
import Analytics from './pages/Analytics';
import Approve from './pages/secure/Approve';
import MetaMap from './pages/Metamap';
import Onboarding from './pages/Onboarding';


function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5533FF'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "BrutalType-Regular",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontSize: 18
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Login title={"Mercuryopal | Crypto Investment for Everyday Use"} />} />
          <Route exact path='/login' element={<Login title={"Mercuryopal | Crypto Investment for Everyday Use"} />} />
          <Route exact path='/create-account/:email' element={<Signup title={"Mercuryopal | Crypto Investment for Everyday Use"} />} />
          <Route exact path='/enter-password/:email' element={<Password title={"Mercuryopal | Crypto Investment for Everyday Use"} />} />
          <Route exact path='/dashboard' element={<Layout />}>
            <Route exact path="" element={<Dashboard title="Mercuryopal | Dashboard" />} />
            <Route exact path="deposit" element={<Deposit title="Mercuryopal | Investment Plan" />} />
            <Route exact path="fund/:plan" element={<Fund title="Mercuryopal | Fund A Wallet" />} />
            <Route exact path="history" element={<History title="Mercuryopal | Transactions" />} />
            <Route exact path="analytics" element={<Analytics title="Mercuryopal | Investment Analytics" />} />
            <Route exact path="settings" element={<Settings title="Mercuryopal | Account Settings" />} />
          </Route>
          <Route exact path='/onboarding' element={<Onboarding title={"Mercuryopal | Complete Your Account"} />} />
          <Route exact path='/metamap' element={<MetaMap title={"Mercuryopal | Verify Identity"} />} />
          <Route exact path='/secure/approve/:id/:tid' element={<Approve title={"Mercuryopal | Aprove Transaction"} />} />
          <Route exact path='*' element={<NotFound title={"Mercuryopal | Page Not Found"} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
