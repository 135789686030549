import { Typography } from "@mui/material";
import Mode from "../components/styles/Mode";
import Header from "../components/Header";
import Toast from "../components/Toast";
import CustomGrid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../components/styles/Custombutton";
import requests from "../handlers/requests";
import { useNavigate } from "react-router-dom"
import Flexbox from "../components/Flexbox";
import { Pulsar } from "@uiball/loaders";
import { isMobile } from "react-device-detect";

const Login = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const mode = useSelector(state => state.darkmode.value)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [email, setEmail] = useState("")

    const handleClick = () => {
        if (email.includes("@") && email.includes(".")) {
            setLoading(true)
            requests.makePost('/api/login', { email }, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    navigate(res.url)
                },
                null
            )
        } else {
            setToastMsg("Enter valid email")
            setSeverity("error")
            setOpen(true)
        }
    }

    return (
        <Mode>
            <div className="login-page">
                <Spacebox padding={isMobile ? "20px" : '0px'}>
                    <Header />
                </Spacebox>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Spacebox padding="20px" />
                <CustomGrid grid={isMobile ? 1 : 2} gap={0}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" sx={isMobile ? { margin: 0, fontSize: '30px' } : { margin: 0 }}>
                            Let's get going
                        </Typography>
                        <Typography style={isMobile ? { fontSize: '16px' } : {}}>
                            Wallet sign in for new and registered users
                        </Typography>
                        <Spacebox padding="10px" />
                        <small>Email</small>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="invest@mercuryo.io"
                        />
                        <Spacebox padding="2.5px" />
                        <small className="light-text">By continuing, you agree with our <a style={{ color: "var(--primary)" }} href="https://mercuryo.io/legal/terms?utm_source=my.mercuryo&utm_medium=referral">Terms of Service</a></small>
                        <Spacebox padding="10px" />
                        <CustomButton
                            backgroundColor="var(--primary)"
                            color={mode.dark ? "black" : "white"}
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={handleClick}
                            className="fullwidth"
                        >
                            {!loading && "Continue"}
                            {loading && (
                                <Flexbox justifyContent="center" alignItems="center">
                                    <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                                </Flexbox>
                            )}
                        </CustomButton>
                    </div>
                    <div className="hide-on-med-and-down" style={{ textAlign: 'center' }}>
                        {mode.dark && <img style={{ width: '100%' }} src="/assets/auth_black.png" alt="" />}
                        {!mode.dark && <img style={{ width: '100%' }} src="/assets/auth_white.png" alt="" />}
                    </div>
                </CustomGrid>
            </div>
        </Mode>
    );
}

export default Login;