import { Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import CustomButton from "../components/styles/Custombutton";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Deposit = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const navigate = useNavigate()

    const classes = [
        {
            title: 'Stocks',
            img: '/assets/stocks_mp.png',
            risk: "high",
            desc: 'Our Stocks plan is perfect for you if you\'ve got a huge risk appetite and would like to own a slice of high-growth companies like Microsoft, Alphabet, and Apple. Our team of professional Stock traders have delivered historical return 8.5% per month to our investors.',
            performance: [
                {
                    year: 2019,
                    rate: "27.50%"
                },
                {
                    year: 2020,
                    rate: "40.77%"
                },
                {
                    year: 2021,
                    rate: "3.55%"
                },
                {
                    year: 2022,
                    rate: "-22.86%"
                },
                {
                    year: 2023,
                    rate: "22.00%"
                },
            ],
            recommended: false,
            action: (index) => navigate(`/dashboard/fund/${index}`)
        },
        {
            title: 'Crypto',
            img: '/assets/crypto_mp_new.png',
            risk: "low",
            desc: 'Our Crypto plan is perfect for you if you\'ve got a low risk appetite and want to protect and preserve their money in a secure, appreciating currency i.e BTC, ETH, XPR. This plan returns 5% - 7% per month, guaranteed',
            performance: [
                {
                    year: 2022,
                    rate: "45.06%"
                },
                {
                    year: 2023,
                    rate: "52.00%"
                },
            ],
            recommended: false,
            action: (index) => navigate(`/dashboard/fund/${index}`)
        },
        {
            title: 'Real Estate',
            img: '/assets/real-estate_mp.png',
            risk: "Mid",
            desc: 'Best for those who want a balance of good returns and medium risk. This plan invests in rented buildings in the US and returns 12 - 15% per annum.',
            performance: [
                {
                    year: 2019,
                    rate: "17.74%"
                },
                {
                    year: 2020,
                    rate: "15.18%"
                },
                {
                    year: 2021,
                    rate: "15.09%"
                },
            ],
            recommended: false,
            action: (index) => navigate(`/dashboard/fund/${index}`)
        },
        {
            title: 'Mercuryo Special',
            img: '',
            risk: "Low",
            desc: '[New] Our Mercuryo special is a custom investment plan which allows flexibility of your investment across all current investment plans. This 3-in-1 plan allows our traders move your investment across all current investments allowing faster and greater ROI. This plan returns 11.5% - 13% monthly.',
            performance: [
                {
                    year: 2021,
                    rate: "28.85%"
                },
                {
                    year: 2022,
                    rate: "25.39%"
                },
                {
                    year: 2023,
                    rate: "21.63%"
                },
            ],
            recommended: true,
            action: (index) => navigate(`/dashboard/fund/${index}`)
        },
    ]

    return (
        <div className="deposit-page">
            <Typography variant={isMobile ? 'h6' : "h4"} style={{ fontWeight: 800, textAlign: 'center' }}>
                Investment Plans
            </Typography>
            <Typography style={isMobile ? {fontSize: '16px'} : {}} textAlign={"center"}>
                Select your preferred investment plan. Each plan comes with it's risk/reward level.
            </Typography>
            <Spacebox padding="20px" />
            <Typography style={isMobile ? {fontSize: '16px'} : {}}>
                Assset classes
            </Typography>
            <Spacebox padding="5px" />
            <CustomGrid grid={isMobile ? 1 : "2"} gap="10px">
                {classes.map((plan, index) => (
                    <div key={index} className={"shiny " + plan.title} style={plan.recommended ? {background: 'var(--primary)'} : {}}>
                        <CustomGrid grid="1" gap="10px">
                            <div>
                                <Typography variant="h5" style={{fontWeight: 800}}>
                                    {plan.title}
                                </Typography>
                                <small className="lighten-text">Risk level: {plan.risk}</small>
                                <Spacebox padding="5px" />
                                <span>{plan.desc}</span>
                                <Spacebox padding="5px" />
                                <Typography style={{ fontWeight: '800' }}>
                                    Performance
                                </Typography>
                                <Flexbox alignItems={"center"} style={{flexWrap: 'wrap'}}>
                                    {plan.performance.map((pm, index2) => (
                                        <div style={{textAlign: 'center', padding: '10px 20px'}} key={index2}>
                                            <small style={{letterSpacing: '3px'}}>{pm.year}</small>
                                            <Spacebox padding="2.5px" />
                                            <span>{pm.rate}</span>
                                        </div>
                                    ))}
                                </Flexbox>
                                <Spacebox padding="5px" />
                                <CustomButton backgroundColor={plan.recommended ? "#fff" : "#089981"} color={plan.recommended ? "var(--primary)" : "#fff"} borderRadius="10px" padding="15px 0px" style={{width: '100%'}} handleClick={() => plan.action(index)}>
                                    Invest in {plan.title}
                                </CustomButton>
                            </div>
                        </CustomGrid>
                    </div>
                ))}
            </CustomGrid>
            <Spacebox padding="10px" />
        </div>
    );
}

export default Deposit;