import { Skeleton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import ArrowPagination from "../components/ArrowPagination";
import Flexbox from "../components/Flexbox";
import string from "../utilities/Strings";
import CustomButton from "../components/styles/Custombutton";
import { isMobile } from "react-device-detect";

const History = ({ title }) => {
    document.querySelector("title").innerHTML = title

    const mode = useSelector(state => state.darkmode.value)

    const [transactions, setTransactions] = useState(null)
    const [filteredTransac, setFilteredTransac] = useState(null)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(true)

    const [type, setType] = useState('')
    const [status, setStatus] = useState('')

    const updateTransactions = (val, filter) => {
        if (filter === 'type') {
            let data = transactions.filter(tran => tran.type === val);
            setTimeout(() => {
                setFilteredTransac(data)
            }, 100);
            setType(val)
        } else {
            let data = transactions.filter(tran => tran.status === val);
            setTimeout(() => {
                setFilteredTransac(data)
            }, 100);
            setStatus(val)
        }
    }

    const clearFilter = () => {
        setTransactions(null)
    }

    useEffect(() => {
        requests.makeGet('/api/transactions', setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setTransactions(res.data)
            },
            null
        )
        // eslint-ignore-next-line
    }, [transactions])

    const statusColor = {
        "complete": "aquamarine",
        "failed": "red",
        "pending": "lightblue"
    }

    const statusElement = (status) => (
        <Flexbox alignItems={"center"} justifyContent={"space-between"} style={{ padding: '5px', border: '1px solid #dbdbdb4a', borderRadius: '1000px', width: '100px' }}>
            <small>{string.capitalise(status)}</small>
            <Spacebox padding="5px 10px" />
            <div style={{
                background: `${statusColor[status]}`,
                width: '10px',
                height: '10px',
                borderRadius: '1000px',
            }}></div>
        </Flexbox>
    )

    return (
        <div className="transactions">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Typography variant={isMobile ? 'h6' : "h4"} style={{ fontWeight: 800, textAlign: 'center' }}>
                Transactions
            </Typography>
            <Typography style={isMobile ? {fontSize: '16px'} : {}} textAlign={"center"}>
                Recent transactions below
            </Typography>
            <Spacebox padding="20px" />
            {loading && (
                <Skeleton width="100%" height="400px" />
            )}
            {filteredTransac && (
                <div>
                    <Flexbox alignItems="center">
                        <Spacebox padding="10px" style={{ border: '1px solid #e8e8e850', borderRadius: '10px' }}>
                            <Flexbox alignItems="center">
                                <span><b>Type</b></span>
                                <Spacebox padding="10px" />
                                <select
                                    name="type"
                                    value={type}
                                    onChange={(e) => updateTransactions(e.target.value, 'type')}
                                >
                                    <option value="Deposit">Deposit</option>
                                    <option value="Withdraw">Withdraw</option>
                                </select>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding="20px" />
                        <Spacebox padding="10px" style={{ border: '1px solid #e8e8e850', borderRadius: '10px' }}>
                            <Flexbox alignItems="center">
                                <span><b>Status</b></span>
                                <Spacebox padding="10px" />
                                <select
                                    name="status"
                                    value={status}
                                    onChange={(e) => updateTransactions(e.target.value, 'status')}
                                >
                                    <option value="pending">Pending</option>
                                    <option value="complete">Complete</option>
                                    <option value="failed">Failed</option>
                                </select>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding="20px" />
                        <CustomButton backgroundColor={"transparent"} color={"#fff"} borderRadius="0px" padding="0px" handleClick={() => clearFilter()}>
                            <span>
                                <b>
                                    Clear filter
                                </b>
                            </span>
                        </CustomButton>
                    </Flexbox>
                    <Spacebox padding="10px" />
                    <div className="table-holder hide-scrollbar">
                        <table className={mode.dark ? "dark-table" : "light-table"}>
                            <thead>
                                <tr>
                                    <td>DATE</td>
                                    <td>AMOUNT</td>
                                    <td>TYPE</td>
                                    <td>DESCRIPTION</td>
                                    <td>STATUS</td>
                                </tr>
                            </thead>
                            {(filteredTransac && filteredTransac.length > 0) && (
                                <tbody>
                                    {(filteredTransac).map((transac, index) => (
                                        <tr key={index}>
                                            <td>{(new Date(transac.timestamp)).toString().substring(0, 16)}</td>
                                            <td>{(transac.amount).toLocaleString()} <small style={{ fontSize: '8px' }}>BTC</small></td>
                                            <td>{transac.type}</td>
                                            <td>{transac.desc}</td>
                                            <td>{statusElement(transac.status)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {(filteredTransac && filteredTransac.length === 0) && (
                                <tbody>
                                    <tr style={{ columnSpan: 'all' }}>
                                        <span style={{ display: 'block', textAlign: 'center' }} className="lighten-text">No records found</span>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            )}
            {transactions && (
                <ArrowPagination data={transactions} setShowData={setFilteredTransac} limit={10} />
            )}
        </div>
    );
}

export default History;