import { useDispatch, useSelector } from "react-redux";
import Darkmode from "./Darkmode";
import Lightmode from "./Lightmode";
import { Container } from "@mui/material";
import "../../css/auth.css"
import { useEffect } from "react";
import cookies from "../../utilities/Cookies";
import { updatedarkmode } from "../../features/mode";

const Mode = ({ children }) => {
    const mode = useSelector(state => state.darkmode.value)
    const dispatch = useDispatch()

    const dark_style = {
        background: "#0A0A0A",
        width: '100%',
        minHeight: '100vh'
    }

    const light_style = {
        background: "white",
        width: '100%',
        minHeight: '100vh'
    }

    useEffect(() => {
        const mode_storage = cookies.getCookies("mode")
        if(mode_storage) {
            const obj = {
                dark: mode_storage === "dark" ? true : false
            }
            dispatch(updatedarkmode(obj))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mode" style={mode.dark ? dark_style : light_style}>
            <Container sx={{ padding: '20px 0px' }}>
                {!mode.dark && (
                    <Lightmode>
                        {children}
                    </Lightmode>
                )}
                {mode.dark && (
                    <Darkmode>
                        {children}
                    </Darkmode>
                )}
            </Container>
        </div>
    );
}

export default Mode;