import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Typography } from "@mui/material";
import Spacebox from "../../components/styles/Spacebox";
import Flexbox from "../../components/Flexbox";
import CustomButton from "../../components/styles/Custombutton";
import requests from "../../handlers/requests";
import Toast from "../../components/Toast";
import { useState } from "react";

const Approve = () => {

    const { id, tid } = useParams()
    const { data: transaction, error, isLoading } = useFetch(`/api/transaction?uid=${id}&tid=${tid}`)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [, setLoading] = useState(false);

    const approve = () => {
        requests.makeGet(`/api/approve/transaction?uid=${id}&tid=${tid}`, setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                
            },
            "Transaction approved"
        )
    }

    const decline = () => {
        requests.makeGet(`/api/decline/transaction?uid=${id}&tid=${tid}`, setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {

            },
            "Transaction declined"
        )
    }

    return (
        <div className="transactions" style={{maxWidth: '400px', margin: 'auto'}}>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Spacebox padding="20px" />
            {isLoading && (
                <Typography>
                    Please Wait
                </Typography>
            )}
            {error && (
                <Typography>
                    An error occured
                </Typography>
            )}
            {transaction && (
                <div>
                    {/* transactions */}
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <div>
                            <Typography>
                                Amount
                            </Typography>
                            <small>{transaction.amount}</small>
                        </div>
                        <div>
                            <Typography>
                                Status
                            </Typography>
                            <small>{transaction.status}</small>
                        </div>
                        <div>
                            <Typography>
                                Percentage
                            </Typography>
                            <small>{transaction.percentage}</small>
                        </div>
                    </Flexbox>
                    <Spacebox padding="40px" />
                    {/* buttons */}
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <CustomButton
                            backgroundColor="#ff0000"
                            color="#fff"
                            padding="10px 20px"
                            handleClick={decline}
                            borderRadius={"5px"}
                        >
                            Decline transaction
                        </CustomButton>

                        <CustomButton
                            backgroundColor="#00ffbc"
                            color="#fff"
                            padding="10px 20px"
                            handleClick={approve}
                            borderRadius={"5px"}
                        >
                            Approve transaction
                        </CustomButton>
                    </Flexbox>
                </div>
            )}
        </div>
    );
}

export default Approve;